import path from 'path';
let lang = {};
const importAll = (r) => r.keys().forEach(r => {
  const filePath = path.join(__dirname, './modules', r);
  const content = require(`.${filePath}`);
  const moduleName = filePath.match(/modules\/(.*)\/fr.js/);
  if (moduleName !== null && moduleName[1] !== undefined) {
    lang[moduleName[1]] = content.default;
  }
});
importAll(require.context('./modules', true, /fr\.js$/));

export default {
  // Web site global label
  global: {
    site: 'Lumenwerx',
    developed_by: 'Développé par',
    switchlang: 'English',
    acronymLang: 'EN',
  },
  ...lang,
};
