export default {
  rule: 'Rule',
  rules: 'Rules',
  verbose_rule: 'a rule',
  left_code: 'Code',
  right_code: 'Code',
  symbol: 'Symbol',
  select_product: 'Select a product',
  select_section: 'Select a section',
  select_specstype: 'Select a type',
  select_specsvalue: 'Select a value',
  range: 'Range',
  mandatory: 'Mandatory',
  implies_not: 'Not',
  product_configuration: 'Product configuration',
  search_specification: 'Search specifications',
};
