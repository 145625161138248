export default {
  methods: {
    getPageOffset () {
      // TODO: Dynamise offset by reading header height
      return 96;
    },
    /**
     * Format byte size into readable string
     *
     * @param {number} size - File size in bytes
     * @param {string[]} [units] - Ascending units used by the string
     */
    formatWeight (size, units = [
      'units.kb',
      'units.mb',
      'units.gb'
    ]) {
      let weight = size;

      for (const unit of units) {
        weight = weight / 1024;

        if (weight < 1000 || unit === units[units.length - 1]) {
          return `${weight.toFixed(1)} ${this.$t(unit)}`;
        }
      }
    },
    /**
     * Get image dimensions from File or src
     *
     * @param {File|string} image
     * @returns {{ width: number, height: number }}
     */
    async getImageDimensions (image) {
      if (image == null) {
        throw new Error ('`image` must be a File or a string');
      }

      let src;

      // Convert file argument to data string
      if (image instanceof File) {
        src = await new Promise((resolve, reject) => {
          const fileReader = new FileReader();

          fileReader.onload = () => resolve(fileReader.result);
          fileReader.onerror = () => reject(fileReader.error);
          fileReader.readAsDataURL(image);
        });
      } else {
        src = image;
      }

      // Create image element and get dimensions
      const { width, height } = await new Promise((resolve, reject) => {
        const imageElement = new Image();

        imageElement.onload = () => resolve(imageElement);
        imageElement.onerror = () => reject;
        imageElement.src = src;
      });

      return { width, height };
    },
    /**
     * Format image dimensions
     *
     * @param {File|string} image
     * @returns {String}
     */
    formatImageDimensions (width, height) {
      return `${width} x ${height} pixels`;
    },
  },
};
