export default {
  projects: 'Projets',
  project: 'Projet',
  verbose_project: 'un projet',
  related_projects: 'Projets reliés',
  meta_description: 'Description meta',
  description: 'Description',
  place: 'Endroit',
  year: 'Année',
  images: 'Images',
  image_rollover: 'Image de survol',
  image_slides: 'Diaporama d\'images',
  content: 'Contenu',
  validation: {
    year: 'Format: AAAA',
  },
  messages: {
    project_not_found: 'Projet non trouvé',
  },
  front_path: 'fr/projets',
};
