export default {
  section: 'Section',
  sections: 'Sections',
  verbose_section: 'une section',
  modules: 'Modules',
  general_info: 'Informations générales',

  status: 'État',
  title: 'Titre',
  subtitle: 'Sous-titre',
  slug: 'Slug',
  meta_title: 'Titre meta',
  meta_description: 'Description meta',
  rows: 'Rangées',
};
