export default {
  section: 'Section',
  sections: 'Sections',
  verbose_section: 'a section',
  modules: 'Modules',
  general_info: 'General information',

  status: 'Status',
  title: 'Title',
  subtitle: 'Subtitle',
  slug: 'Slug',
  meta_title: 'Meta title',
  meta_description: 'Meta Description',
  rows: 'Rows',
};
