export default {
  product: 'Product',
  products: 'Products',
  productsection: 'Section',
  productspecstype: 'Type',
  productspecsvalue: 'Value',
  verbose_product: 'a product',
  code: 'Code',
  meta_description: 'Meta Description',
  image_rollover: 'Rollover',
  related_products: 'Related products',
  configurator: 'Configurator',
  documents: 'Documents',
  products_count: 'Products count',
  messages: {
    product_not_found: 'Product not found',
    incompatible_rules_not_added: 'Some incompatible rules were not added',
    incompatible_rule: 'This rule is incompatible with the product configuration'
  },
  front_path: 'products',
};
