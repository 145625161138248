import * as slug from 'slugify';
import sections from '../global/sections';

export default {
    id: 'projects',
    idSingular: 'project',
    typeName: 'project',
    saveable: true,
    label: 'project.projects',
    label_details: 'project.project',
    verbose_label_singular: 'project.verbose_project',
    itemBreadcrumb: 'title',
    i18n: true,
    previewUrl: (item, i18n) => `${process.env.VUE_APP_PROJECT_URL}/${i18n('project.front_path')}/${slug(item.title, { replacement: '-', lower: true })}-${item.id}?cmspreview=true`,
    menu: {
      title: 'project.projects',
      icon: 'folder',
    },
    submodules: [
      {
        id: 'projects.participants',
        title: 'participant.participants',
        path: '/participants'
      },
      {
        id: 'projects.participanttypes',
        title: 'participanttype.participanttypes',
        path: '/participanttypes'
      },
    ],
    groupFields: [
      {
        key: 'main',
        label: 'generic.general_info',
        hashLink: 'general-info',
        panel: true,
      },
      {
        key: 'images',
        label: 'project.images',
        hashLink: 'images',
        panel: true,
      },
      {
        key: 'content',
        label: 'project.content',
        hashLink: 'content',
        panel: true,
      },
      {
        key: 'categories',
        label: 'category.categories',
        hashLink: 'categories',
        panel: true,
      },
      {
        key: 'projects',
        label: 'project.related_projects',
        hashLink: 'related_projects',
        panel: true,
      },
      {
        key: 'products',
        label: 'product.related_products',
        hashLink: 'products',
        panel: true,
      },
      {
        key: 'participants',
        label: 'participant.participants',
        hashLink: 'participants',
        panel: true,
      },
      {
        key: 'awards',
        label: 'award.awards',
        hashLink: 'awards',
        panel: true,
      },
    ],
    fields: [
      {
        key: 'id',
        groupkey: 'main',
        label: 'ID',
        type: 'text',
        fetch: {
          edit: true,
          listing: true,
        },
      },
      {
        key: 'status',
        type: 'status',
        label: 'generic.state',
        display: {
          edit: true,
          add: true,
          listing: true,
        },
        listingStyle: {
          columnWidth: 80,
        },
      },
      {
        key: 'title',
        groupkey: 'main',
        label: 'generic.title',
        type: 'text',
        required: true,
        international: true,
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        search: true,
      },
      {
        key: 'meta_description',
        groupkey: 'main',
        label: 'project.meta_description',
        type: 'textarea',
        international: true,
        display: {
          add: true,
          edit: true,
        },
        search: true,
        props: {
          counter: 140,
        },
      },
      {
        key: 'description',
        groupkey: 'main',
        label: 'project.description',
        type: 'htmltexteditor',
        required: true,
        international: true,
        display: {
          edit: true,
          add: true,
        },
        search: true,
      },
      {
        key: 'place',
        groupkey: 'main',
        label: 'project.place',
        type: 'text',
        required: true,
        international: true,
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        props: {
          hint: 'Format: MANHATTAN, NY, USA',
        },
        search: true,
      },
      {
        key: 'year',
        groupkey: 'main',
        label: 'project.year',
        type: 'number',
        required: true,
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        props: {
          counter: 4,
          rules: [
              value => value && value > -1 && value < 10000
              || 'project.validation.year',
          ],
        },
        search: true,
      },
      {
        key: 'image',
        groupkey: 'images',
        label: 'generic.image',
        type: 'media',
        related: 'image { id, filename, file, type, dimensions, weight }',
        listing_attribute: 'file',
        listing_attributes: 'id,file',
        display: {
          listing: true,
          edit: true,
          add: true,
        },
        international: true,
        props: {
          typeFilter: ['image/%']
        },
      },
      {
        key: 'image_rollover',
        groupkey: 'images',
        label: 'project.image_rollover',
        type: 'media',
        related: 'image_rollover { id, filename, file, type, dimensions, weight }',
        listing_attribute: 'file',
        listing_attributes: 'id,file',
        display: {
          edit: true,
          add: true,
        },
        international: true,
        props: {
          typeFilter: ['image/%']
        },
      },
      {
        key: 'image_slides',
        groupkey: 'images',
        label: 'project.image_slides',
        type: 'media',
        related: 'image_slides { id, filename, file, type, dimensions, weight }',
        listing_attribute: 'file',
        listing_attributes: 'id,file',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          multiple: true,
          typeFilter: ['image/%']
        },
      },
      {
        key: 'categories',
        groupkey: 'categories',
        label: 'category.categories',
        type: 'relations',
        related: 'categories { id, title, type { id, title } }',
        related_id: 'categories',
        idSingular: 'category',
        related_fields: 'id, title',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          table: true,
          tableProps: {
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'type',
              label: 'categorytype.categorytype',
              type: 'select',
              display: {
                table: true,
              },
            },
          ],
          actions: {
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'projects',
        groupkey: 'projects',
        label: 'project.related_projects',
        type: 'relations',
        related: 'projects { id, title, place, year, image { id, filename, file, type, dimensions, weight } }',
        related_id: 'projects',
        idSingular: 'project',
        related_fields: 'id, title, place, year',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        filters: [
          {
            id: 'id',
            select: 'id',
            type: 'single',
            whereClause: 'id[!=]',
          }
        ],
        props: {
          table: true,
          tableProps: {
            rankDrag: true,
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'place',
              label: 'project.place',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'year',
              label: 'project.year',
              type: 'number',
              display: {
                table: true,
              },
            },
            {
              key: 'image',
              label: 'generic.image',
              type: 'media',
              related: 'image { id, filename, file, type, dimensions, weight }',
              listing_attribute: 'file',
              listing_attributes: 'id,file',
              display: {
                table: true,
              },
            },
          ],
          actions: {
            toEditRows: true,
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'products',
        groupkey: 'products',
        label: 'product.related_products',
        type: 'relations',
        related: 'products { id, title, status, image { id, filename, file, type, dimensions, weight } }',
        related_id: 'products',
        idSingular: 'product',
        related_fields: 'id, title, status',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          table: true,
          tableProps: {
            rankDrag: true,
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'status',
              label: 'generic.state',
              type: 'status',
              display: {
                table: true,
              },
            },
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'image',
              label: 'generic.image',
              type: 'media',
              groupkey: 'main',
              related: 'image { id, filename, file, type, dimensions, weight }',
              listing_attribute: 'file',
              listing_attributes: 'id,file',
              display: {
                table: true,
              },
              props: {
                typeFilter: ['image/%']
              },
            },
          ],
          actions: {
            toEditRows: true,
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'participants',
        groupkey: 'participants',
        label: 'participant.participants',
        type: 'relations',
        related: 'participants { id, name, participanttype { id, title }, link, email }',
        related_id: 'participants',
        idSingular: 'participant',
        related_fields: 'id, name, link, email',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        filters: [
          {
            id: 'id',
            select: 'id',
            whereClause: 'id',
          }
        ],
        props: {
          table: true,
          tableProps: {
            rankDrag: true,
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'name',
              label: 'generic.name',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'participanttype',
              label: 'participanttype.participanttype',
              type: 'select',
              display: {
                table: true,
              },
            },
            {
              key: 'link',
              label: 'generic.link',
              type: 'text',
              display: {
                table: true,
              },
            },
            {
              key: 'email',
              label: 'generic.email',
              type: 'email',
              display: {
                table: true,
              },
            },
          ],
          actions: {
            toEditRows: true,
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      {
        key: 'awards',
        groupkey: 'awards',
        label: 'award.awards',
        type: 'relations',
        related: 'awards { id, title }',
        related_id: 'awards',
        idSingular: 'award',
        related_fields: 'id, title',
        relationType: 'multiple',
        col: 'col-12',
        display: {
          edit: true,
        },
        props: {
          table: true,
          tableProps: {
            hideDefaultFooter: true,
            disablePagination: true,
            disableSort: true,
          },
          relatedFields: [
            {
              key: 'title',
              label: 'generic.title',
              type: 'text',
              display: {
                table: true,
              },
            }
          ],
          actions: {
            deleteRows: true,
            addRows: true,
            selectRows: true,
          },
        },
      },
      { ...sections, parent_module: 'project' },
      {
        key: 'created_since',
        groupkey: 'main',
        label: 'generic.created_since',
        type: 'text',
        sortClause: 'created_at',
        display: {
          listing: true,
        },
        listingStyle: {
          columnWidth: 95,
        },
      },
    ]
  };
