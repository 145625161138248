import Vue from 'vue';
import Vuetify from 'vuetify/lib';

export default function useVuetify ({ vueOptions, xms }) {
  Vue.use(Vuetify);

  vueOptions.vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: xms.theme.global.primary,
          accent: xms.theme.global.accent,
          border: xms.theme.global.border,
          background: xms.theme.global.background,
          navprimary: xms.theme.nav.primary,
          navsecondary: xms.theme.nav.secondary,
        },
      },
    },
    icons: {
      iconfont: xms.theme.global.iconFont,
    },
  });
}
