export default {
  id: 'mediacategories',
  idSingular: 'mediacategory',
  saveable: true,
  typeName: 'mediacategory',
  label: 'mediacategory.mediacategories',
  label_details: 'mediacategory.edit_mediacategories',
  verbose_label_singular: 'mediacategory.verbose_mediacategory',
  itemBreadcrumb: 'title',
  i18n: true,
  treeview: true,
  treeviewMaxLevel: 4,
  rankable: true,
  filters: [],
  groupFields: [
    {
      key: 'info',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      listingStyle: {
        columnWidth: 80,
      },
      display: {
        listing: true,
      },
    },
    {
      key: 'title',
      groupkey: 'info',
      label: 'generic.title',
      type: 'text',
      required: true,
      search: true,
      international: true,
      display: {
        add: true,
        listing: true,
        treeview: true,
        edit: true,
      },
    },
    {
      key: 'code',
      groupkey: 'info',
      label: 'generic.code',
      type: 'text',
      display: {
        edit: true,
      },
      props: {
        disabled: true,
      }
    },
    {
      key: 'parents',
      related: 'parents { ids }',
      fetch: {
        edit: true
      }
    }
  ]
};
