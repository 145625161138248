export default {
  global: {
    primary: '#1f1f1f',
    accent: '#EA4E56',
    background: '#F8F8F8',
    border: '#cbcbcb',
    iconFont: 'mdi',
    favicon: 'favicon.png',
  },
  nav: {
    primary: '#1f1f1f',
    secondary: '#161616',
  },
  login: {
    logo: 'logo.jpg',
    logoEmail: 'logo-email.jpg',
    splash: 'splash.jpg',
  },
};

