export default {
    users: 'Utilisateurs',
    user: 'Utilisateur',
    verbose_user: 'un utilisateur',
    firstname: 'Prénom',
    lastname: 'Nom de famille',
    email: 'Courriel',
    password: 'Mot de passe',
    gender: 'Sexe',
    mobile: 'Mobile',
    phone: 'Téléphone',
    last_login_date: 'Connexion',
    users_listing: 'Utilisateurs',
    function: 'Poste',
    confirm_password: 'Confirmer le mot de passe',
    pending: 'En attente',
    man: 'Homme',
    woman: 'Femme',
};
