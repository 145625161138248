export default {
  updateContentLang (state, { contentLang }) {
    state.contentLang = contentLang;
  },
  updateTargetContentLang (state, { contentLang }) {
    state.targetContentLang = contentLang;
  },
  startUpdateContentLang (state) {
    state.contentLangUpdating = true;
  },
  endUpdateContentLang (state) {
    state.contentLangUpdating = false;
    state.targetContentLang = null;
  },
};
