import { get } from 'lodash';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

import config from '@/../project/config/core.config';

const permissions = config && config.userRights !== undefined
  ? config.userRights
  : {};

// Check if permissions are disabled globally
const disablePermissionsGlobally = config && config.disablePermissions !== undefined
  ? config.disablePermissions
  : false;

// Check if permissions are for each module
const permissionsDisabledForModules = {};
const modules = config && config.modules !== undefined
  ? config.modules
  : {};
const modulesArray = Object.keys(modules);
modulesArray.forEach(item => {
  const moduleConfig = require(`@/../project/config/modules/${item}`);
  const modulePermissionsDisabled = _.get(moduleConfig, 'default.disablePermissions', false);
  permissionsDisabledForModules[item] = modulePermissionsDisabled;

  const moduleTypes = _.get(moduleConfig, 'default.types', []);
  moduleTypes.forEach(type => {
    permissionsDisabledForModules[type.id] = modulePermissionsDisabled;
  });
});

const state = () => ({
  token: null,
  user: {},
  roles: [],
  requestedPath: null,
  defaultPermissionObject: permissions,
  userPermissions: [],
  permissionsDisabledGlobally: disablePermissionsGlobally,
  permissionsDisabledForModules,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
