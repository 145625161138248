export default {
  block: 'Block',
  blocks: 'Blocks',
  verbose_block: 'a block',
  general_info: 'General information',

  position: 'Position',
  text_align: 'Text Alignment',
  height: 'Height',
  width: 'Width',
  min_height: 'Minimum Height',
  max_width: 'Maximum Width',
  bk_color: 'Background color',
  border_color: 'Border color',
  text_color: 'Text color',

  href: 'Link',
  targetblank: 'Target blank',
  fit_height_to_bk_image: 'Fit height to background image',
  content_type: 'Content type',
  html: 'Text',
  image: 'Image',
  video: 'Video',
  button: 'Button',
  content: 'Content',
};
