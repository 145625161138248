import sections from '../global/sections';

export default {
  id: 'categorytypes',
  idSingular: 'categorytype',
  saveable: true,
  typeName: 'categorytype',
  label: 'categorytype.categorytypes',
  label_details: 'categorytype.categorytype',
  verbose_label_singular: 'categorytype.verbose_categorytype',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'categories',
      label: 'category.categories',
      hashLink: 'categories',
      panel: true,
    },
    {
      key: 'builder',
      label: 'Builder',
      hashLink: 'builder',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'code',
      groupkey: 'main',
      label: 'generic.code',
      type: 'text',
      display: {
        edit: true,
        add: true,
      },
      props: {
        disabled: true,
      },
      search: true,
    },
    {
      key: 'categories',
      groupkey: 'categories',
      label: 'category.categories',
      type: 'relations',
      related: 'categories { id, title, type { id, title } }',
      related_id: 'categories',
      idSingular: 'category',
      related_fields: 'id, title',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
        ],
        actions: {
          deleteRows: false,
          addRows: false,
          selectRows: false,
        },
      },
    },
    { ...sections, parent_module: 'categorytype' },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
