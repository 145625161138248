export default {
  id: 'participanttypes',
  idSingular: 'participanttype',
  saveable: true,
  typeName: 'participanttype',
  label: 'participanttype.participanttypes',
  label_details: 'participanttype.participanttype',
  verbose_label_singular: 'participanttype.verbose_participanttype',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
