export default {
  award: 'Award',
  awards: 'Awards',
  verbose_award: 'an award',
  content: 'Content',
  event_date: 'Event date',
  published_at: 'Publication date',
  messages: {
    award_not_found: 'Award not found',
  },
  front_path: 'awards',
};
