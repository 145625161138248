<template>
  <div
    v-if="showContentLang"
    class="content-lang"
  >
    <!-- Desktop -->
    <template v-if="!!$vuetify.breakpoint.smAndUp">
      <v-icon class="mr-1">
        language
      </v-icon>
      <v-btn
        v-for="item in items"
        :key="item"
        :style="contentLang !== item ? 'opacity: 0.5' : null"
        :disabled="contentLangUpdating"
        class="text-capitalize px-1"
        small
        text
        @click="handleUpdateContentLang(item)"
      >
        {{ $t(`contentLang.${item}`) }}
      </v-btn>
    </template>
    <!-- Mobile -->
    <template v-else>
      <v-menu>
        <template v-slot:activator="{ on }">
          <div v-on="on">
            <v-badge
              :content="activeItem"
              color="accent"
              overlap
              class="font-weight-bold"
            >
              <v-icon>
                language
              </v-icon>
            </v-badge>
          </div>
        </template>
        <v-list>
          <v-list-item
            v-for="item in items"
            :key="item"
            :class="{
              'is-active': contentLang === item,
            }"
            :disabled="contentLangUpdating"
            @click="handleUpdateContentLang(item)"
          >
            <v-list-item-title>{{ $t(`contentLang.${item}`) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'BaseContentLang',
  computed: {
    ...mapState('content', ['contentLang', 'contentLangUpdating']),
    items () {
      let items = [];
      Object.values(this.$xms.config.modules).forEach(module => {
        if (module.contentLang) {
          items = items.concat(module.contentLang);
        }
      });
      return items;
    },
    /**
     * Gives the lang in the array that is equal to the
     * current content lang (for mobile badge display)
     *
     * @returns {string}
     */
    activeItem () {
      return this.items.find(item => item === this.contentLang);
    },
    showContentLang () {
      return this.items.length > 1;
    },
  },
  methods: {
    ...mapActions({
      updateContentLang: 'content/updateContentLang',
    }),
    handleUpdateContentLang (item) {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/lang/content',
        eventLabel: item,
      });
      this.updateContentLang({ contentLang: item });
    },
  }
};
</script>

<style lang="scss" scoped>
// General
.content-lang {
  display: flex;
  align-items: center;
}

// List
.v-list-item {
  opacity: 0.6;

  &.is-active {
    opacity: 1;
  }
}

// Badge (visible on mobile only)
::v-deep .v-badge__badge {
  display: flex;
  align-items: center;
  justify-content: center;

  @include rem(width, 18px);
  @include rem(height, 18px);

  min-width: 0;

  @include rem(padding, 2px 0 0);
  @include rem(font-size, 9px);

  text-transform: uppercase;
}
</style>
