export default {
    users: 'Users',
    user: 'User',
    verbose_user: 'a user',
    firstname: 'First Name',
    lastname: 'Last Name',
    email: 'Email',
    password: 'Password',
    gender: 'Genre',
    mobile: 'Mobile',
    phone: 'Phone',
    last_login_date: 'Last Login',
    users_listing: 'User list',
    function: 'Function',
    confirm_password: 'Confirm password',
    pending: 'Pending',
    man: 'Man',
    woman: 'Woman',
};
