export default {
  event: 'Event',
  events: 'Events',
  verbose_event: 'an event',
  event_date: 'Event date',
  published_at: 'Publication date',
  messages: {
    event_not_found: 'Event not found',
  },
};
