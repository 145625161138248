import sections from '../global/sections';

export default {
  id: 'categories',
  idSingular: 'category',
  saveable: true,
  typeName: 'category',
  label: 'category.categories',
  label_details: 'category.category',
  verbose_label_singular: 'category.verbose_category',
  itemBreadcrumb: 'title',
  i18n: true,
  menu: {
    title: 'category.categories',
    icon: 'category',
  },
  submodules: [
    {
      id: 'categories.categorytypes',
      title: 'categorytype.categorytypes',
      path: '/categorytypes'
    },
  ],
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'builder',
      label: 'Builder',
      hashLink: 'builder',
      panel: true,
    },
    {
      key: 'categories',
      label: 'category.related_categories',
      hashLink: 'related_categories',
      panel: true,
    },
    {
      key: 'products',
      label: 'product.products',
      hashLink: 'products',
      panel: true,
    },
    {
      key: 'projects',
      label: 'project.projects',
      hashLink: 'projects',
      panel: true,
    },
    {
      key: 'specstypes',
      label: 'specstype.specstypes',
      hashLink: 'specstypes',
      panel: true,
    },
  ],
  filters: [
    {
      id: 'categorytypes',
      type: 'select',
      whereClause: 'type_id',
      label: 'categorytype.select_categorytypes',
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        edit: true,
        listing: true,
      },
      search: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'description',
      groupkey: 'main',
      label: 'generic.description',
      type: 'textarea',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'code',
      groupkey: 'main',
      label: 'generic.code',
      type: 'text',
      display: {
        edit: true,
      },
      props: {
        disabled: true,
      },
      search: true,
    },
    {
      key: 'link',
      groupkey: 'main',
      label: 'generic.link',
      type: 'text',
      display: {
        edit: true,
        add: true,
      },
      props: {
        rules: [
          value => (value === '' || value === null) || value && value.length <= 300 || 'errors.max_300',
          value => {
            const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
            return (value === '' || value === null) || value && pattern.test(value) || 'errors.link_wrong_format';
          },
        ]
      },
      international: true,
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      groupkey: 'main',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'type',
      groupkey: 'main',
      label: 'categorytype.categorytype',
      type: 'select',
      related: 'type { id, title, code }',
      related_key: 'categorytypes',
      related_id: 'type_id',
      related_table: 'categorytypes',
      related_fields: [
        'id',
        'title',
      ],
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      itemText: 'title',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
      options: [
        'no_backup_lang',
      ],
      required: true,
    },
    {
      key: 'products',
      groupkey: 'products',
      label: 'product.related_products',
      type: 'relations',
      related: 'products { id, title, status, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'products',
      idSingular: 'product',
      related_fields: 'id, title, status',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'status',
            label: 'generic.state',
            type: 'status',
            display: {
              table: true,
            },
          },
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'categories',
      groupkey: 'categories',
      label: 'category.related_categories',
      type: 'relations',
      related: 'categories { id, title, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'categories',
      idSingular: 'category',
      related_fields: 'id, title',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          type: 'single',
          whereClause: 'id[!=]',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'specstypes',
      groupkey: 'specstypes',
      label: 'specstype.specstypes',
      type: 'relations',
      related: 'specstypes { id, title, code }',
      related_id: 'specstypes',
      idSingular: 'specstype',
      related_fields: 'id, title, code',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
          {
            key: 'code',
            label: 'generic.code',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'projects',
      groupkey: 'projects',
      label: 'project.related_projects',
      type: 'relations',
      related: 'projects { id, title, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'projects',
      idSingular: 'project',
      related_fields: 'id, title',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    { ...sections, parent_module: 'category' },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
