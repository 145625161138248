export default {
  id: 'notes',
  idSingular: 'note',
  saveable: true,
  typeName: 'note',
  label: 'note.notes',
  label_details: 'note.note',
  verbose_label_singular: 'note.verbose_note',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'options',
      label: 'note.options',
      hashLink: 'options',
      panel: true,
    },
    {
      key: 'related_specs',
      label: 'note.related_specs',
      hashLink: 'related_specs',
      panel: true,
    },
  ],
  filters: [
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'text',
      groupkey: 'main',
      label: 'note.text',
      type: 'textarea',
      cols: '6',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
    },
    {
      key: 'display_configurator',
      groupkey: 'options',
      label: 'note.display_configurator',
      type: 'boolean',
      display: {
        edit: true,
        add: true,
      },
      props: {
        defaultValue: true,
      },
    },
    {
      key: 'specstypes',
      groupkey: 'related_specs',
      label: 'specstype.specstypes',
      type: 'relations',
      related: 'specstypes { id, title, code }',
      related_id: 'specstypes',
      idSingular: 'specstype',
      related_fields: 'id, title, code',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
          {
            key: 'code',
            label: 'generic.code',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'specsvalues',
      groupkey: 'related_specs',
      label: 'specsvalue.specsvalues',
      type: 'relations',
      related: 'specsvalues { id, title, code }',
      related_id: 'specsvalues',
      idSingular: 'specsvalue',
      related_fields: 'id, title, code',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
          {
            key: 'code',
            label: 'generic.code',
            type: 'text',
            display: {
              table: true,
              edit: true,
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
  ]
};
