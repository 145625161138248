import Vue from 'vue';

function installEventBus (Vue) {
  // Register global event bus
  Vue.prototype.$eventBus = new Vue();
}

export default function useEventBus () {
  Vue.use(installEventBus);
}
