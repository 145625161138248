export default {
  rule: 'Règle',
  rules: 'Règles',
  verbose_rule: 'une règle',
  left_code: 'Code',
  right_code: 'Code',
  symbol: 'Symbole',
  select_product: 'Sélectionner un produit',
  select_section: 'Sélectionner une section',
  select_specstype: 'Sélectionner un type',
  select_specsvalue: 'Sélectionner une valeur',
  range: 'Interval',
  mandatory: 'Obligatoire',
  implies_not: 'Non',
  product_configuration: 'Configuration du produit',
  search_specification: 'Chercher une spécification',
};
