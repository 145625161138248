<template>
  <div
    v-if="trailItems && trailItems.length > 0"
    class="breadcrumbs"
  >
    <template
      v-for="(trailItem, index) in trailItems"
    >
      <span
        v-if="index + 1 !== trailItems.length"
        :key="trailItem.title"
      >
        <router-link
          :to="trailItem.path"
          :class="['text-decoration-none', { 'font-weight-bold': index + 1 === trailItems.length }]"
        >
          {{ isItem(trailItem.path) === true ? trailItem.title : $t(trailItem.title) }}
        </router-link>
        /
      </span>
      <template v-else>
        <span
          :key="trailItem.title"
          class="font-weight-bold"
        >
          <template>
            {{ isItem(trailItem.path) === true ? trailItem.title : $t(trailItem.title) }}
          </template>
        </span>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  data () {
    return {
      metaInfos: null,
    };
  },
  computed: {
    trailItems () {
      const trailItems = [];

      this.$route.matched.forEach(route => {
        const title = this.getTitleFromRoute(route);

        if (title != null) {
          trailItems.push({ path: route.path, title });
        }
      });
      this.handleMetaInfos(trailItems);

      return trailItems;
    },
  },
  metaInfo () {
    return {
      title: this.metaInfos,
    };
  },
  methods: {
    /**
     * We create a string from the current trail infos
     * and we apply them to metaInfos data title
     *
     * Returns Void
     */
    handleMetaInfos (trailItems) {
      let metaInfos = '';

      trailItems.forEach((trailItem, index) => {
        const title = this.isItem(trailItem.path) === true ? trailItem.title : this.$t(trailItem.title);
        if (index + 1 !== trailItems.length) {
          metaInfos += `${title} - `;
        } else {
          metaInfos += title;
        }
      });

      this.metaInfos = `${metaInfos} | `;
    },
    getTitleFromRoute (route) {
      if (route && route.meta && route.meta.title) {
        const titleProp = route.meta.title;

        if (typeof titleProp === 'string') {
          return titleProp;
        }

        if (typeof titleProp === 'function') {
          return titleProp.call(this);
        }
      }

      return undefined;
    },
    // Check path if we are in a detail view related to the current item
    isItem (path) {
      return /.*\/\:id\/\w+$/.test(path);
    }
  },
};
</script>

<style lang="scss" scoped>
// General
.breadcrumbs {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// Modifiers
.text-decoration-none {
  text-decoration: none;
}
</style>
