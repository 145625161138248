export default {
  note: 'Note',
  notes: 'Notes',
  verbose_note: 'a note',
  text: 'Text',
  options: 'Options',
  use_display_exception: 'Use exceptions',
  display_configurator: 'Display configurator',
  related_specs: 'Related specs',
};
