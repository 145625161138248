<template>
  <v-navigation-drawer
    :key="locked"
    v-model="drawer"
    :width="$vuetify.breakpoint.smAndUp ? 280 : 256"
    :expand-on-hover="expandOnHover === true"
    mobile-break-point="960"
    mini-variant-width="80"
    color="navprimary"
    dark
    app
    @mouseover.native="handleMouseEvent"
    @mouseleave.native="handleMouseEvent"
  >
    <!-- Header -->
    <template v-slot:prepend>
      <v-list
        class="header"
        dense
      >
        <v-list-item>
          <template v-if="expanded === true">
            <v-list-item-content>
              <v-list-item-title>
                <v-toolbar-title
                  v-t="'global.site'"
                  class="font-weight-bold"
                />
              </v-list-item-title>
            </v-list-item-content>
            <v-tooltip
              color="accent"
              right
              transition="scroll-x-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  :class="[
                    'header__lock',
                    { 'is-active': locked === true },
                  ]"
                  v-bind="attrs"
                  v-on="on"
                  @click="toggleDrawerLock"
                />
              </template>
              <span>{{ tooltipLabel }}</span>
            </v-tooltip>
          </template>
        </v-list-item>
      </v-list>
    </template>
    <v-list
      class="py-0"
      expand
      dense
    >
      <!-- Navigation items -->
      <template v-for="item in items">
        <!-- Group with submodules -->
        <v-list-group
          v-if="item.submodules && filterItemSubmodules(item).length > 0"
          :key="item.title"
          :value="handleActiveGroup(item)"
          :class="{
            'is-mini': expanded === false,
            'has-active-module': handleActiveGroup(item),
          }"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-2">
              <v-icon v-html="item.icon" />
            </v-list-item-icon>
            <v-list-item-title v-t="item.title" />
          </template>
          <!-- Item -->
          <v-list-item
            v-if="canReadModule(item.id)"
            :key="item.title"
            :to="item.path"
            :class="expanded === true ? 'pl-12' : 'pl-7'"
            sub-group
            @click="handleMenuItemClick(item.path)"
          >
            <v-list-item-title v-text="getTitleName(item)" />
          </v-list-item>
          <!-- Submodule(s) -->
          <v-list-item
            v-for="submodule in filterItemSubmodules(item)"
            :key="submodule.title"
            :to="submodule.path"
            :class="expanded === true ? 'pl-12' : 'pl-7'"
            sub-group
            @click="handleMenuItemClick(submodule.path)"
          >
            <v-list-item-title v-text="getTitleName(submodule)" />
          </v-list-item>
        </v-list-group>
        <!-- Item without submodules -->
        <v-list-item
          v-else-if="canReadModule(item.id)"
          :key="item.title"
          :to="item.path"
          @click="handleMenuItemClick(item.path)"
        >
          <v-list-item-icon class="mr-2">
            <v-icon v-html="item.icon" />
          </v-list-item-icon>
          <v-list-item-title v-t="item.title" />
        </v-list-item>
        <v-divider
          v-if="canReadModule(item.id)"
          :key="`divider.${item.title}`"
          class="divider"
        />
      </template>
    </v-list>
    <!-- Copyrights -->
    <template v-slot:append>
      <v-list
        class="copyrights"
        dense
      >
        <v-list-item>
          <a
            href="https://ax2.ca/"
            target="_blank"
          ><img :src="require('@/assets/img/ax2-logo.svg')"></a>
          <v-list-item-title
            class="body-2 ml-3"
            v-html="`Copyright © ${date}`"
          />
        </v-list-item>
      </v-list>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
export default {
  name: 'NavigationDrawer',
  data () {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp === true,
      expandOnHover: this.$vuetify.breakpoint.mdAndUp === true,
      expanded: this.$vuetify.breakpoint.smAndDown === true,
      locked: false,
      switchingLang: false,
    };
  },
  computed: {
    ...mapGetters('auth', ['canReadModule']),
    tooltipLabel () {
      return this.$t(`ui.${this.locked === true ? 'menu_minified' : 'menu_expanded'}`);
    },
    items () {
      return this.$xms.menuItems;
    },
    breakpointMdOnly () {
      return this.$vuetify.breakpoint.mdOnly === true;
    },
    switchView () {
      return this.$vuetify.breakpoint.mdAndUp === true;
    },
    date () {
      const date = new Date();
      return date.getFullYear();
    },
  },
  watch: {
    drawer (value) {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/drawer/navigation/toggle-visibility',
        eventLabel: `visible: ${value}`,
      });
    },
    breakpointMdOnly (value) {
      this.uiSetContextualDrawerMini(value);
    },
    switchView (value) {
      if (value === true) {
        this.expandOnHover = !this.locked;
        this.expanded = this.locked;
      } else {
        this.expandOnHover = false;
        this.expanded = true;
      }
    },
  },
  created () {
    this.$eventBus.$on('ui/handleNavigationDrawer', this.handleNavigationDrawer);

    if (localStorage.getItem('navdrawer_locked') === 'locked') {
      this.locked = true;
      this.expandOnHover = false;
      this.expanded = true;
    }
  },
  methods: {
    ...mapMutations('ui', ['uiSetContextualDrawerMini']),
    // Toggles the mobile navigation drawer
    // Return Void
    handleNavigationDrawer () {
      this.drawer = !this.drawer;
    },
    getTitleName (item) {
      const title = item.submenutitle !== undefined ? item.submenutitle : item.title;
      if (this.expanded === true) {
        return this.$t(title);
      }
      return `${this.$t(title).slice(0, 2)}.`;
    },
    // Open group if one of is submodule is the current path
    // Return Boolean
    handleActiveGroup (item) {
      const hasActiveModule = this.$route.path.match(new RegExp(`${item.path}(/|$)`, 'gi'));
      const hasActiveSubmodule = !hasActiveModule
        ? item.submodules.some(element => this.$route.path.match(new RegExp(`${element.path}(/|$)`, 'gi')))
        : false;
      return hasActiveModule || hasActiveSubmodule;
    },
    // Given an item, filters its submodules according to permissions
    filterItemSubmodules (item) {
      return item.submodules.filter(submodule => {
        const submoduleKey = submodule.id;
        return this.canReadModule(submoduleKey);
      });
    },
    handleMouseEvent (event) {
      if (this.$vuetify.breakpoint.mdAndUp === true) {
        if (event.type === 'mouseover') {
          this.expanded = true;
        } else if (this.locked === false) {
          this.expanded = false;
        }
      }
    },
    toggleDrawerLock () {
      this.locked = !this.locked;
      this.expandOnHover = !this.expandOnHover;
      this.expanded = !this.expanded;
      localStorage.setItem('navdrawer_locked', this.locked ? 'locked' : null);
    },
    // Send analytics
    handleMenuItemClick (path) {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/module/view',
        eventLabel: path,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
// Header
.header {
  padding: 0;

  .v-list-item {
    background-color: var(--color-nav-secondary);

    @media (min-width: $bp-md) {
      @include rem(height, 80px);
    }
  }
}

.header__lock {
  display: none;
  position: relative;

  @include rem(width, 10px);
  @include rem(height, 10px);
  @include rem(margin-right, 7px);

  border: 1px solid var(--color-border);
  border-radius: 50%;
  transition: border 300ms;
  cursor: pointer;

  &::after {
    content: '';
    display: block;
    position: absolute;

    @include rem(top, 1px);
    @include rem(right, 1px);
    @include rem(bottom, 1px);
    @include rem(left, 1px);

    background-color: var(--color-accent);
    border-radius: 50%;
    opacity: 0;
    transition: opacity 300ms;
  }

  &:hover,
  &.is-active {
    border-color: var(--color-accent);

    &::after {
      display: block;
      opacity: 1;
    }
  }

  @media (min-width: $bp-md) {
    display: block;
  }
}

// Icons
::v-deep {
  .v-list-item__icon {
    align-self: center;
  }

  .v-list-group__header__append-icon {
    @include rem(min-width, 24px, !important);

    .v-icon {
      transform: rotate(-90deg) !important;

      .v-list-group--active & {
        transform: rotate(0deg) !important;
      }
    }
  }

  // List
  // stylelint-disable-next-line
  .v-list-item {
    align-items: center;

    @include rem(min-height, 48px);
  }

  .v-list-group {
    .v-list-group__items .v-list-item {
      @include rem(min-height, 36px);
    }
  }

  .v-list-item--active {
    color: var(--color-accent);

    &::before {
      opacity: 0;
    }

    &.v-list-group__header {
      color: white;

      .v-list-group.is-mini.has-active-module & {
        color: var(--color-accent);
      }
    }
  }
}

// Copyrights
.copyrights {
  padding: 0;
  border-radius: 0;

  // stylelint-disable-next-line
  .v-list-item {
    background-color: var(--color-accent);

    @include rem(height, 48px);

    @media (min-width: $bp-md) {
      @include rem(height, 80px);
    }

    img {
      @include rem(width, 40px);

      filter: brightness(0) invert(1);
    }
  }

  .v-list-item__title {
    text-align: right;
  }
}

// Divider
.divider {
  border-color: var(--color-border);
}
</style>
