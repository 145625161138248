import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';

function installSnackbar (Vue) {

  function buildSnackbarArray (snackbars, defaultSnackbar) {
    const defaultType = defaultSnackbar.type;
    const defaultMessage = defaultSnackbar.message;
    const snackbarArray = Array.isArray(snackbars)
      ? snackbars
      : [snackbars];

    const snackbarsToCommit = snackbarArray
      .map(({ message = defaultMessage, type = defaultType}) => ({ id: Date.now(), message, type }));
    return snackbarsToCommit;
  };

  Vue.prototype.$reportMessage = function reportMessage (snackbars) {
    this.showSnackbar(buildSnackbarArray(snackbars, 'info'));
  };

  Vue.prototype.$reportError = function reportError (
    snackbars,
    defaultSnackbar = { type: 'error', message: 'errors.error_occured'},
  ) {
    this.showSnackbar(buildSnackbarArray(snackbars, defaultSnackbar));
  };

  Vue.prototype.$reportSuccess = function reportSuccess (
    snackbars,
    defaultSnackbar = { type: 'success', message: 'dialogs.update_success'},
  ) {
    this.showSnackbar(buildSnackbarArray(snackbars, defaultSnackbar));
  };

  Vue.mixin({
    computed: {
      ...mapGetters({
        isLoggedIn: 'auth/isLoggedIn',
        canAccess: 'auth/canAccess',
        userName: 'auth/userName',
        userEmail: 'auth/userEmail',
        userId: 'auth/userId',
      }),
    },
    methods: {
      ...mapActions({
        showSnackbar: 'ui/showSnackbar',
      }),
    },
  });
}

export default function useSnackbar () {
  Vue.use(installSnackbar);
}
