export default {
  /* Returns false if lang update would destroy unsaved changes
   * Returns true if lang update is safe to proceed
   *
   * @param      {Object}    arg1             Vuex parameters
   * @param      {Function}  arg1.commit      Commit method
   * @param      {Object}    arg1.rootState   Root State object
   * @param      {Function}  arg1.dispatch    Dispatch method
   * @param      {Object}    arg2             Method parameters
   * @param      {String}    arg2.contentLang The language we are switching to
   * @return     {Boolean}                    Can updateLang proceed
   */
  confirmUpdatePossible ({ commit, rootState, dispatch }, { contentLang = null }) {
    const formHasUnsavedChanges = _.get(rootState, 'crud.formHasUnsavedChanges', false);
    // If the current page has unsaved changes, we display a warning
    if (formHasUnsavedChanges) {
      dispatch('crud/showUnsavedWarning', {}, { root: true });
      commit('updateTargetContentLang', { contentLang });
    }
    return !formHasUnsavedChanges;
  },
  /* Update Content Language method
   *
   * @param      {Object}    arg1             Vuex parameters
   * @param      {Function}  arg1.commit      Commit method
   * @param      {Object}    arg1.state       State object
   * @param      {Object}    arg1.rootState   Root State object
   * @param      {Function}  arg1.dispatch    Dispatch method
   * @param      {Object}    arg2             Action options
   * @param      {String}    arg2.contentLang The language we are switching to
   * @param      {Boolean}   arg2.force       Circumvent checks
   * @return     {null}
   */
  async updateContentLang ({ commit, state, rootState, dispatch }, { contentLang = null, force = false }) {
    if (state.contentLangUpdating || state.contentLang === contentLang) {
      return;
    }
    // if parameter force === true, we proceed anyways, if not we must check if update is possible
    const canProceed = force === false
      ? await dispatch('confirmUpdatePossible', { contentLang })
      : true;

   if (canProceed) {
      commit('startUpdateContentLang');
      commit('updateContentLang', { contentLang });

      // Update local storage before refetch so that apollo gets the correct language
      localStorage.setItem('content_lang', contentLang);
      // Reset store & callback end content lang update once complete
      setTimeout(async () => {
        await this.$apollo.defaultClient.resetStore();
        commit('endUpdateContentLang');
      }, 500);
    }
  },
};
