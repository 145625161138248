export default {
  article: 'Article',
  articles: 'Articles',
  verbose_article: 'an article',
  related_articles: 'Related articles',
  content: 'Content',
  news: 'News',
  event_date: 'Event date',
  published_at: 'Publication date',
  messages: {
    article_not_found: 'Article not found',
  },
  front_path: 'news',
};
