/* eslint-disable global-require */

import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import ui from './ui';
import content from './content';

function createStore () {
  Vue.use(Vuex);

  const store = new Vuex.Store({
    modules: {
      auth,
      ui,
      content,
    },
  });

  // Enable store modules hot reloading
  if (module.hot) {
    module.hot.accept(['./auth', './ui', './content'], () => {
      store.hotUpdate({
        modules: {
          auth: require('./auth').default,
          ui: require('./ui').default,
          content: require('./content').default,
        },
      });
    });
  }

  return store;
}

const store = createStore();
export default store;
