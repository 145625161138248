export default {
  note: 'Note',
  notes: 'Notes',
  verbose_note: 'une note',
  text: 'Texte',
  options: 'Options',
  use_exceptions: 'Utiliser les exceptions',
  display_configurator: 'Afficher configurateur',
  related_specs: 'Spécifications reliées',
};
