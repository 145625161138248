export default {
  specstype: 'Type',
  specstypes: 'Types',
  verbose_specstype: 'a type',
  options: 'Options',
  is_multiple: 'Multiple',
  is_optional: 'Optional',
  display_options: 'Display options',
  display_configurator: 'Configurator',
  use_exceptions: 'Use exceptions',
  select_specstypes: 'Select a type',
};
