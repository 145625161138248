export default {
  projects: 'Projects',
  project: 'Project',
  verbose_project: 'a project',
  related_projects: 'Related projects',
  meta_description: 'Meta Description',
  description: 'Description',
  place: 'Place',
  year: 'Year',
  images: 'Images',
  image_rollover: 'Rollover image',
  image_slides: 'Image slides',
  content: 'Content',
  validation: {
    year: 'Format: AAAA',
  },
  messages: {
    project_not_found: 'Project not found',
  },
  front_path: 'projects',
};
