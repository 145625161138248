export { default as loadMediaManager } from '@ax2/xms-media-manager-module';

export default {
  userRights: {
    1: 'read',
  },
  disablePermissions: false,
  id: 'medias',
  idSingular: 'media',
  itemTitle: 'filename',
  itemLocales: 'i18n',
  formats: ['jpg', 'png', 'mp4', 'pdf', 'zip'],
  filterCategories: true,
  filterTypeItems: [
    {
      text: 'generic.image',
      value: 'image/',
    },
    {
      text: 'generic.video',
      value: 'video/',
    },
    {
      text: 'generic.pdf',
      value: 'application/pdf',
    },
    {
      text: 'generic.zip',
      value: 'application/zip',
    },
  ],
  filters: [
    {
      id: 'mediacategories',
      type: 'select',
      whereClause: 'mediacategory_id',
      label: 'mediacategory.select_mediacategories',
    },
  ],
  sizeLimit: 250 * 1024 * 1024,
  menu: {
    title: 'mediamanager.title',
    submenutitle: 'Medias',
    icon: 'cloud_upload',
  },
  submodules: [
    {
      id: 'medias.mediacategories',
      title: 'mediacategory.mediacategories',
      path: '/mediacategories'
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'id',
      type: 'number',
      fetch: {
        listing: true,
        details: true,
      }
    },
    {
      key: 'file',
      label: 'file',
      type: 'image',
      fetch: {
        listing: true,
        details: true,
      }
    },
    {
      key: 'title',
      label: 'title',
      type: 'text',
      sortable: true,
      search: true,
      required: true,
      display: {
        listing: true,
        edit: true,
      },
    },
    {
      key: 'filename',
      label: 'filename',
      type: 'text',
      display: {
        listing: true,
        details: true,
      },
    },
    {
      key: 'type',
      label: 'type',
      sortable: true,
      fetch: {
        listing: true,
        details: true,
      },
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: (media) => {
          if (!media) return false;
          return media.type.match(/(application\/.*)|(video\/.*)/g);
        },
        listing: true,
      },
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'created_at',
      label: 'created_at',
      type: 'date',
      display: {
        details: true,
      }
    },
    {
      key: 'weight',
      label: 'weight',
      type: 'weight',
      sortable: true,
      display: {
        details: true,
      }
    },
    {
      key: 'dimensions',
      label: 'dimensions',
      type: 'dimensions',
      display: {
        details: true,
      }
    },
    {
      key: 'alt',
      label: 'alt',
      type: 'text',
      display: {
        edit: (media) => {
          if (!media) return false;
          return media.type.match(/(image\/.*)|(video\/.*)/g);
        },
      }
    },
    {
      key: 'mediacategories',
      label: 'category.categories',
      type: 'combobox',
      related: 'mediacategories { id, title }',
      related_id: 'mediacategories',
      related_table: 'mediacategories',
      related_fields: [
        'id', 'title',
      ],
      sortClause: 'mediacategory.title',
      itemValue: 'title',
      options: [
        'no_backup_lang',
      ],
      display: {
        listing: true,
        edit: true,
      },
      props: {
        attach: true,
        multiple: true,
        deletableChips: true,
        smallChips: true,
        menuProps: {
          maxHeight: 300,
          closeOnContentClick: true,
        },
      },
    },
    {
      key: 'caption',
      label: 'caption',
      type: 'text',
      display: {
        edit: (media) => {
          if (!media) return false;
          return media.type.match(/(image\/.*)|(video\/.*)/g);
        },
      }
    },
    {
      key: 'description',
      label: 'description',
      type: 'textarea',
      display: {
        edit: true,
      }
    },
    {
      key: 'credits',
      label: 'credits',
      type: 'text',
      display: {
        edit: (media) => {
          if (!media) return false;
          return media.type.match(/(image\/.*)|(video\/.*)/g);
        },
      }
    },
    {
      key: 'created_at',
      label: 'created_at',
      sortable: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      }
    }
  ]
};
