import Vue from 'vue';
import config, { loadModules as loadConfigModules } from '@/../project/config/core.config';
import theme from '@/../project/config/theme.config';

function installConfig (Vue, xms) {
  Vue.prototype.$xms = xms;
};

/**
 * Use Config.
 * Prepares the core XMS service.
 * @param {AppContext} context
 */
export function useConfig (context) {
  const { store } = context;

  const xms = {
    config,
    theme,
    menuItems: [],
  };

  Vue.use(installConfig, xms);

  context.xms = xms;
  store.$xms = xms;
}

export function loadModules (context) {
  if (typeof loadConfigModules === 'function') {
    loadConfigModules(context);
  }
}
