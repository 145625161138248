export default {
  uiSnackbarsShow (state, snackbarsArray) {
    state.snackbars = snackbarsArray;
  },
  uiSnackbarsHide (state) {
    state.snackbars = [];
  },
  uiSetContextualDrawerMini (state, mini = false) {
    state.contextualDrawerIsMini = mini;
  },
  uiSetPageFullyLoaded (state, loaded = true) {
    state.pageFullyLoaded = loaded;
  },
};
