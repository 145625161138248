import Vue from 'vue';

// Synchronous global components
import BaseContentLang from '../components/ContentLang/BaseContentLang';
import BaseSpinner from '../components/Spinner/BaseSpinner';

function installGlobalComponents (Vue) {
  Vue.component('BaseContentLang', BaseContentLang);
  Vue.component('BaseSpinner', BaseSpinner);
}

export default function useGlobalComponents () {
  Vue.use(installGlobalComponents);
}
