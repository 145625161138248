export default {
  id: 'articletypes',
  idSingular: 'articletype',
  saveable: true,
  typeName: 'articletype',
  label: 'articletype.articletypes',
  label_details: 'articletype.articletypes',
  verbose_label_singular: 'articletype.verbose_articletype',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
