<template>
  <div>
    <v-snackbar
      v-for="(snackbar, index) in snackbars"
      :key="`${snackbar.id}${index}`"
      :value="snackbar.id"
      :color="snackbar.type"
      :multi-line="true"
      :timeout="3000"
      :bottom="true"
      :right="true"
      :style="computedStyle(index)"
    >
      {{ $t(snackbar.message) }}
      <v-btn
        dark
        text
        @click="hideSnackbar()"
      >
        {{ $t('ui.close') }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'SnackbarContainer',
  data: () => ({
    onLeaveCallback: null,
  }),
  computed: {
    ...mapState('ui', ['snackbars']),
  },
  methods: {
    ...mapActions({
      hideSnackbar: 'ui/hideSnackbar',
    }),
    computedStyle (index) {
      return `margin-bottom: ${75 * index}px`;
    },
  },
};
</script>
