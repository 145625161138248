import Vue from 'vue';

import globalMixin from '../mixins/global';

function installGlobalMixins (Vue) {
  Vue.mixin(globalMixin);
}

export default function useGlobalMixins () {
  Vue.use(installGlobalMixins);
}
