export default {
  product: 'Produit',
  products: 'Produits',
  productsection: 'Section',
  productspecstype: 'Type',
  productspecsvalue: 'Valeur',
  verbose_product: 'un produit',
  code: 'Code',
  meta_description: 'Description meta',
  image_rollover: 'Survol',
  related_products: 'Produits reliés',
  configurator: 'Configurateur',
  documents: 'Documents',
  products_count: 'Nombre de produits',
  messages: {
    product_not_found: 'Produit non trouvé',
    incompatible_rules_not_added: 'Certaines règles incompatibles n\'ont pas été ajoutées',
    incompatible_rule: 'Cette règle est incompatible avec la configuration du produit'
  },
  front_path: 'fr/produits',
};
