import getters from './getters';
import actions from './actions';
import mutations from './mutations';

import config from '@/../project/config/core.config';

const state = () => ({
  contentLang: localStorage.getItem('content_lang') || _.get(config, 'modules.crud.contentLangDefault', 'en'),
  contentLangUpdating: false,
  targetContentLang: null,
});

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
