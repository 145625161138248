export default {
  id: 'participants',
  idSingular: 'participant',
  saveable: true,
  typeName: 'participant',
  label: 'participant.participants',
  label_details: 'participant.participant',
  verbose_label_singular: 'participant.verbose_participant',
  itemBreadcrumb: 'name',
  filters: [
    {
      id: 'participanttype',
      type: 'select',
      whereClause: 'participanttype_id',
      label: 'participanttype.select_participanttype',
    },
  ],
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
    },
    {
      key: 'name',
      groupkey: 'main',
      label: 'generic.name',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
    },
    {
      key: 'participanttype',
      groupkey: 'main',
      label: 'participanttype.participanttype',
      type: 'select',
      related_key: 'participanttypes',
      related: 'participanttype { id, title }',
      related_id: 'participanttype_id',
      related_table: 'participanttypes',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'id',
        'title',
      ],
      display: {
        add: true,
        listing: true,
        edit: true,
      },
      notsortable: true,
    },
    {
      key: 'link',
      groupkey: 'main',
      label: 'generic.link',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      props: {
        counter: 255,
        onFocusDefaultValue: 'http://',
        rules: [
          value => {
            const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
            return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
          },
        ]
      },
    },
    {
      key: 'email',
      groupkey: 'main',
      label: 'generic.email',
      type: 'email',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
