import Vue from 'vue';
import VueI18n from 'vue-i18n';

import defaultFr from '../../lang/index-fr';
import defaultEn from '../../lang/index-en';

export default function useI18n (context) {
  const { xms, vueOptions } = context;

  Vue.use(VueI18n);

  const getXmsLang = localStorage.getItem('lang');
  const i18nConfig = xms.config.i18n || {};

  if (i18nConfig !== {} ) {
    i18nConfig.messages = {
      fr: { ...defaultFr, ...i18nConfig.messages.fr },
      en: { ...defaultEn, ...i18nConfig.messages.en },
    };
  }

  const i18n = new VueI18n({
    locale: getXmsLang,
    silentTranslationWarn: true,
    ...i18nConfig
  });

  context.i18n = i18n;
  vueOptions.i18n = i18n;
}
