import { default as crud, loadCrud } from './modules/crud';
import { default as mediaManager, loadMediaManager } from './modules/mediaManager';

import fr from '../lang/index-fr.js';
import en from '../lang/index-en.js';

export default {
  apiRoot: process.env.VUE_APP_API_ROOT,
  endpoints: {
    signup: '/signup',
    verify: '/verify',
  },
  resetPassword: true,
  adminEmail: 'lx@ax2.ca',
  modules: {
    crud,
    mediaManager,
  },
  i18n: {
    fallbackLocale: 'fr',
    messages: {
      fr,
      en,
    },
  },
  userRights: {
    1: 'read',
    2: 'edit',
    4: 'add',
    8: 'delete',
  },
};

export function loadModules (context) {
  loadCrud(context);
  loadMediaManager(context);
}
