<template>
  <v-dialog
    v-model="modal"
    width="480"
    scrollable
  >
    <!-- App bar icon (modal trigger) -->
    <template v-slot:activator="{ on }">
      <div class="feedback-trigger">
        <v-btn
          icon
          tile
          height="36"
          v-on="on"
        >
          <v-icon>
            {{ modal === true ? 'sentiment_very_satisfied' : 'sentiment_satisfied_alt' }}
          </v-icon>
        </v-btn>
      </div>
    </template>
    <!-- Card -->
    <v-card>
      <!-- Title -->
      <v-card-title
        v-t="'generic.contact_us'"
        class="body-1 elevation-1 font-weight-bold py-3"
      />
      <!-- Tabs -->
      <v-tabs
        v-model="tab"
        :icons-and-text="!!$vuetify.breakpoint.smAndUp"
        grow
        @change="resetForm"
      >
        <v-tab
          v-for="item in items"
          :key="item.tab"
        >
          {{ !!$vuetify.breakpoint.smAndUp ? $t(item.content) : null }}
          <v-icon>{{ item.tab }}</v-icon>
        </v-tab>
      </v-tabs>
      <!-- Content -->
      <v-card-text class="pt-5">
        <v-tabs-items
          v-model="tab"
          touchless
        >
          <!-- Report bug -->
          <v-tab-item
            :key="'bug_report'"
            :transition="false"
            :reverse-transition="false"
          >
            <v-form
              ref="form-report"
              v-model="report.form"
            >
              <p v-t="'report.bug_report_description'" />
              <v-row>
                <v-col cols="12">
                  <label
                    for="field-url"
                    class="label-input"
                    v-text="`${$t('generic.url')} *`"
                  />
                  <v-text-field
                    id="field-url"
                    v-model="report.url"
                    :rules="urlRules"
                    hide-details="auto"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <label
                    for="field-desc"
                    class="label-input"
                    v-text="`${$t('generic.description')} *`"
                  />
                  <v-textarea
                    id="field-desc"
                    v-model="report.description"
                    :rules="requiredRule"
                    hide-details="auto"
                    outlined
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <label
                    for="field-files"
                    class="label-input"
                    v-text="`${$t('report.files')} *`"
                  />
                  <v-file-input
                    id="field-files"
                    :value="report.files"
                    :prepend-icon="null"
                    :show-size="1000"
                    append-icon="mdi-paperclip"
                    accept="image/*"
                    color="primary"
                    multiple
                    outlined
                    counter
                    dense
                    @click:clear="clearFilesAttachments"
                    @change="onFilePicked"
                  >
                    <template v-slot:selection="{ text, index }">
                      <v-chip
                        small
                        close
                        color="primary"
                        @click:close="deleteAttachments(index)"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
          <!-- Comments / Suggestions -->
          <v-tab-item
            :key="'feedback'"
            :transition="false"
            :reverse-transition="false"
          >
            <v-form
              ref="form-feedback"
              v-model="report.form"
            >
              <p v-t="'report.feedback_description'" />
              <div class="text-center">
                <v-btn-toggle
                  v-model="report.opinion"
                  class="mb-2"
                  color="accent"
                  mandatory
                  group
                >
                  <v-btn>
                    <v-icon color="error">
                      sentiment_very_dissatisfied
                    </v-icon>
                  </v-btn>
                  <v-btn>
                    <v-icon color="warning">
                      sentiment_dissatisfied
                    </v-icon>
                  </v-btn>
                  <v-btn>
                    <v-icon color="yellow darken-1">
                      sentiment_satisfied
                    </v-icon>
                  </v-btn>
                  <v-btn>
                    <v-icon color="success">
                      sentiment_very_satisfied
                    </v-icon>
                  </v-btn>
                </v-btn-toggle>
              </div>
              <v-row>
                <v-col cols="12">
                  <label
                    for="field-description-report"
                    class="label-input"
                    v-text="`${$t('generic.description')} *`"
                  />
                  <v-textarea
                    id="field-description-report"
                    v-model="report.description"
                    :rules="requiredRule"
                    hide-details="auto"
                    outlined
                    dense
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <!-- Actions -->
      <v-card-actions>
        <v-spacer />
        <!-- Close -->
        <v-btn
          v-t="'ui.close'"
          text
          @click="close"
        />
        <!-- Send report -->
        <v-btn
          v-t="'report.send'"
          :disabled="disableSendButton"
          :loading="report.loading"
          color="primary"
          @click="sendReport"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import jwt from 'jsonwebtoken';
import { mapActions } from 'vuex';

export default {
  name: 'FeedBack',
  data () {
    return {
      modal: false,
      tab: 0,
      items: [
        { tab: 'bug_report', content: 'report.bug_report' },
        { tab: 'feedback', content: 'report.feedback' },
      ],
      report: {
        form: false,
        subject: null,
        description: null,
        url: window.location.href,
        files: null,
        loading: false,
        attachments: [],
        opinion: 3,
      },
      requiredRule: [
        value => !!value || this.$t('errors.required'),
      ],
      urlRules: [
        value => {
          const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}|\:[0-9]{1,5}([\/a-z\.\?=0-9&\-\_\#]*)?$/i;
          return (value && pattern.test(value)) || this.$t('errors.link_wrong_format_abs');
        },
      ],
    };
  },
  computed: {
    disableSendButton () {
      return this.report.form === false && this.report.loading === false;
    },
  },
  watch: {
    modal () {
      // Open: Automatically set report URL
      // Close: Reset form and tabs index
      if (this.modal === true) {
        this.$ga.event({
          eventCategory: 'core',
          eventAction: 'ui/feedback/show',
          eventLabel: 'click-modal-button',
        });
        this.report.url = window.location.href;
      } else {
        this.tab = 0;
        this.resetForm();
      }
    },
  },
  methods: {
    /**
     * Set all form info to default value + reset validation is form is defined
     * @returns {void}
     */
    resetForm () {
      this.report = {
        form: false,
        subject: null,
        description: null,
        url: window.location.href,
        files: null,
        loading: false,
        attachments: [],
        opinion: 3, // Default opinion is very satisfied
      };

      // Reset form report validation
      if (this.$refs['form-report'] !== undefined) {
        this.$refs['form-report'].resetValidation();
      }

      // Reset form feedback validation
      if (this.$refs['form-feedback'] !== undefined) {
        this.$refs['form-feedback'].resetValidation();
      }
    },
    close () {
      this.modal = false;
    },
    onFilePicked (files) {
      this.report.loading = true;
      this.report.files = this.report.files
        ? this.report.files
        : [];
      files.forEach(file => {
        if (file !== undefined) {
          const { name } = file;
          const fileAlreadyAttached = this.report.files.find(one => one.name === name);
          if (name.lastIndexOf('.') <= 0 || fileAlreadyAttached) {
            return;
          }
          const fileReader = new FileReader ();
          fileReader.readAsDataURL(file);
          fileReader.addEventListener('load', () => {
            const url = fileReader.result;
            event.target.value = '';
            this.report.attachments.push({ name, file, url });
            this.report.files.push(file);
          });
        }
      });
      this.report.loading = false;
    },
    clearFilesAttachments () {
      this.report.files = null;
      this.report.attachments = [];
    },
    deleteAttachments (index) {
      this.report.files.splice(index, 1);
      this.report.attachments.splice(index, 1);
    },
    async sendReport () {
      this.report.loading = true;
      let formData = null;
      let analyticsAction = null;
      switch (this.tab) {
        case 1: // Feedback
          formData = {
            to: [
              process.env.VUE_APP_MAIL_FEEDBACK,
              this.userEmail
            ],
            subject: 'Feedback',
            body: this.report.description,
            opinion: this.report.opinion,
            name: this.userName,
            template: 'feedback',
          };
          analyticsAction = 'Feedback';
          break;
        case 0: // Bug report
        default:
          formData = {
            to: [
              process.env.VUE_APP_MAIL_BUGREPORT,
              this.userEmail
            ],
            subject: 'Bug Report',
            body: this.report.description,
            url: this.report.url !== null && this.report.url !== '' ? this.report.url : window.location.href,
            attachments: this.report.attachments,
            template: 'bugreport',
          };
          analyticsAction = 'Bug report';
          break;
      }
      formData.locale = this.$i18n.locale;
      const jwtData = {
        email: this.userEmail,
      };
      const token = jwt.sign(jwtData, process.env.VUE_APP_MAIL_JWT_SECRET);
      try {
        // Call to mail API
        const result = await this.axios.post(
          '/send',
          formData,
          {
            baseURL: process.env.VUE_APP_MAIL_URL,
            headers: { 'Authorization': `Bearer ${token}` }
          },
        );
        this.$ga.event({
          eventCategory: 'core',
          eventAction: 'ui/feedback/send',
          eventLabel: analyticsAction,
        });
        if (result.status === 200) {
          this.$reportSuccess({ message: this.$t('report.send_success') });
          this.close();
        } else {
          this.$reportError({ message: this.$t('report.send_errors') });
        }
      } catch (e) {
        this.$reportError({ message: e.message });
      }
      this.report.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-card__title {
  margin-bottom: 1px;
}

::v-deep .v-dialog:not(.v-dialog--fullscreen) {
  max-height: 93%;
}

.feedback-trigger {
  margin-right: $spacer/1.5;

  @media (min-width: $bp-md) {
    border-right: 1px solid var(--color-border);
    border-left: 1px solid var(--color-border);
  }
}
</style>
