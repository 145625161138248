var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{key:_vm.locked,attrs:{"width":_vm.$vuetify.breakpoint.smAndUp ? 280 : 256,"expand-on-hover":_vm.expandOnHover === true,"mobile-break-point":"960","mini-variant-width":"80","color":"navprimary","dark":"","app":""},nativeOn:{"mouseover":function($event){return _vm.handleMouseEvent($event)},"mouseleave":function($event){return _vm.handleMouseEvent($event)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-list',{staticClass:"header",attrs:{"dense":""}},[_c('v-list-item',[(_vm.expanded === true)?[_c('v-list-item-content',[_c('v-list-item-title',[_c('v-toolbar-title',{directives:[{name:"t",rawName:"v-t",value:('global.site'),expression:"'global.site'"}],staticClass:"font-weight-bold"})],1)],1),_c('v-tooltip',{attrs:{"color":"accent","right":"","transition":"scroll-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({class:[
                  'header__lock',
                  { 'is-active': _vm.locked === true } ],on:{"click":_vm.toggleDrawerLock}},'div',attrs,false),on))]}}],null,false,1922294844)},[_c('span',[_vm._v(_vm._s(_vm.tooltipLabel))])])]:_vm._e()],2)],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-list',{staticClass:"copyrights",attrs:{"dense":""}},[_c('v-list-item',[_c('a',{attrs:{"href":"https://ax2.ca/","target":"_blank"}},[_c('img',{attrs:{"src":require('@/assets/img/ax2-logo.svg')}})]),_c('v-list-item-title',{staticClass:"body-2 ml-3",domProps:{"innerHTML":_vm._s(("Copyright © " + _vm.date))}})],1)],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"py-0",attrs:{"expand":"","dense":""}},[_vm._l((_vm.items),function(item){return [(item.submodules && _vm.filterItemSubmodules(item).length > 0)?_c('v-list-group',{key:item.title,class:{
          'is-mini': _vm.expanded === false,
          'has-active-module': _vm.handleActiveGroup(item),
        },attrs:{"value":_vm.handleActiveGroup(item),"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"innerHTML":_vm._s(item.icon)}})],1),_c('v-list-item-title',{directives:[{name:"t",rawName:"v-t",value:(item.title),expression:"item.title"}]})]},proxy:true}],null,true)},[(_vm.canReadModule(item.id))?_c('v-list-item',{key:item.title,class:_vm.expanded === true ? 'pl-12' : 'pl-7',attrs:{"to":item.path,"sub-group":""},on:{"click":function($event){return _vm.handleMenuItemClick(item.path)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getTitleName(item))}})],1):_vm._e(),_vm._l((_vm.filterItemSubmodules(item)),function(submodule){return _c('v-list-item',{key:submodule.title,class:_vm.expanded === true ? 'pl-12' : 'pl-7',attrs:{"to":submodule.path,"sub-group":""},on:{"click":function($event){return _vm.handleMenuItemClick(submodule.path)}}},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(_vm.getTitleName(submodule))}})],1)})],2):(_vm.canReadModule(item.id))?_c('v-list-item',{key:item.title,attrs:{"to":item.path},on:{"click":function($event){return _vm.handleMenuItemClick(item.path)}}},[_c('v-list-item-icon',{staticClass:"mr-2"},[_c('v-icon',{domProps:{"innerHTML":_vm._s(item.icon)}})],1),_c('v-list-item-title',{directives:[{name:"t",rawName:"v-t",value:(item.title),expression:"item.title"}]})],1):_vm._e(),(_vm.canReadModule(item.id))?_c('v-divider',{key:("divider." + (item.title)),staticClass:"divider"}):_vm._e()]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }