export default {
  event: 'Événement',
  events: 'Événements',
  verbose_event: 'un événement',
  event_date: 'Date de l\'événement',
  published_at: 'Date de publication',
  messages: {
    event_not_found: 'Événement non trouvé',
  },
};
