<template>
  <v-navigation-drawer
    v-model="drawer"
    :app="$vuetify.breakpoint.mdAndUp === false"
    :mini-variant="mini === true"
    mobile-break-point="960"
    clipped
    fixed
    right
  >
    <v-btn
      v-if="$vuetify.breakpoint.mdAndUp === true"
      :height="36"
      :width="36"
      :style="contextualDrawerIsMini === false ? 'transform: rotate(180deg);' : null"
      class="ml-2"
      icon
      @click="handleContextualDrawer"
    >
      <v-icon>menu_open</v-icon>
    </v-btn>
    <slot />
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'ContextualDrawer',
  data () {
    return {
      drawer: this.$vuetify.breakpoint.mdAndUp === true,
      mini: this.$vuetify.breakpoint.mdOnly === true,
    };
  },
  computed: {
    ...mapState('ui', ['contextualDrawerIsMini']),
    ...mapState('crud', ['currentModule']),
    breakpointMdAndUp () {
      return this.$vuetify.breakpoint.mdAndUp === true;
    },
    breakpointMdOnly () {
      return !!this.$vuetify.breakpoint.mdOnly === true;
    },
  },
  watch: {
    drawer (value) {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/drawer/contextual/toggle-visibility',
        eventLabel: `visible: ${value}`,
      });
    },
    mini (value) {
      this.$ga.event({
        eventCategory: 'core',
        eventAction: 'ui/drawer/contextual/toggle-mini',
        eventLabel: `mini: ${value}`,
      });
    },
    breakpointMdAndUp (value) {
      this.drawer = value;
    },
    breakpointMdOnly (value) {
      this.mini = value;
      this.uiSetContextualDrawerMini(this.mini);
    },
  },
  mounted () {
    this.$eventBus.$on('ui/handleContextualDrawer', this.handleContextualDrawer);
    this.uiSetContextualDrawerMini(this.mini);
  },
  methods: {
    ...mapMutations('ui', ['uiSetContextualDrawerMini']),
    handleContextualDrawer () {
      if (this.$vuetify.breakpoint.mdAndUp === true) {
        this.mini = !this.mini;
        this.uiSetContextualDrawerMini(this.mini);
      } else {
        this.mini = false;
        this.drawer = !this.drawer;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-navigation-drawer {
  @include rem(top, 0, !important);

  @media (min-width: $bp-md) {
    @include rem(top, 128px, !important);
  }
}
</style>
