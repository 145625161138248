import * as slug from 'slugify';
import blocks from '../global/block';

export default {
  id: 'awards',
  idSingular: 'award',
  saveable: true,
  typeName: 'award',
  label: 'award.awards',
  label_details: 'award.awards',
  verbose_label_singular: 'award.verbose_award',
  itemBreadcrumb: 'title',
  i18n: true,
  filters: [
    {
      id: 'articletype',
      type: 'select',
      whereClause: 'articletype_id',
      label: 'articletype.select_articletype',
    },
    {
      id: 'dates',
      type: 'daterange',
      label: 'award.published_at',
      whereClause: [
        'published_at[>=]',
        'published_at[<=]',
      ],
    },
  ],
  previewUrl: (item, i18n) => `${process.env.VUE_APP_PROJECT_URL}/${i18n('award.front_path')}/${slug(item.title, { replacement: '-', lower: true })}-${item.id}?cmspreview=true`,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
    {
      key: 'images',
      label: 'Images',
      hashLink: 'images',
      panel: true,
    },
    {
      key: 'content',
      label: 'award.content',
      hashLink: 'content',
      panel: true,
    },
    {
      key: 'articles',
      label: 'article.articles',
      hashLink: 'articles',
      panel: true,
    },
    {
      key: 'projects',
      label: 'project.projects',
      hashLink: 'projects',
      panel: true,
    },
    {
      key: 'products',
      label: 'product.products',
      hashLink: 'products',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        listing: true,
        edit: true,
      },
      search: true,
    },
    {
      key: 'status',
      type: 'status',
      label: 'generic.state',
      display: {
        edit: true,
        add: true,
        listing: true,
      },
      listingStyle: {
        columnWidth: 80,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'articletype',
      groupkey: 'main',
      label: 'articletype.articletype',
      type: 'select',
      related_key: 'articletypes',
      related: 'articletype { id, title }',
      related_table: 'articletypes',
      related_id: 'articletype_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        clearable: true,
      }
    },
    {
      key: 'description',
      groupkey: 'main',
      label: 'generic.description',
      type: 'textarea',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
      props: {
        counter: 140,
      },
    },
    {
      key: 'image',
      label: 'generic.image',
      type: 'media',
      groupkey: 'images',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        listing: true,
      },
      props: {
        typeFilter: ['image/%']
      },
      notsortable: true,
    },
    {
      key: 'event_date',
      groupkey: 'main',
      label: 'award.event_date',
      type: 'date',
      display: {
        add: true,
        edit: true,
      },
      required: true,
    },
    {
      key: 'published_at',
      groupkey: 'main',
      label: 'article.published_at',
      type: 'datetime',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
    },
    {
      key: 'image_slides',
      label: 'Slides',
      type: 'media',
      groupkey: 'images',
      related: 'image_slides { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        multiple: true,
        typeFilter: ['image/%']
      },
    },
    {
      key: 'articles',
      groupkey: 'articles',
      label: 'article.related_articles',
      type: 'relations',
      related: 'articles { id, title, status, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'articles',
      idSingular: 'article',
      related_fields: 'id, title, status',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          whereClause: 'id',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'status',
            label: 'generic.state',
            type: 'status',
            display: {
              table: true,
            },
          },
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            groupkey: 'main',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'projects',
      groupkey: 'projects',
      label: 'project.related_projects',
      type: 'relations',
      related: 'projects { id, title, place, year, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'projects',
      idSingular: 'project',
      related_fields: 'id, title, place, year',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      filters: [
        {
          id: 'id',
          select: 'id',
          whereClause: 'id',
        }
      ],
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'place',
            label: 'project.place',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'year',
            label: 'project.year',
            type: 'number',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    {
      key: 'products',
      groupkey: 'products',
      label: 'product.related_products',
      type: 'relations',
      related: 'products { id, title, status, image { id, filename, file, type, dimensions, weight } }',
      related_id: 'products',
      idSingular: 'product',
      related_fields: 'id, title, status',
      relationType: 'multiple',
      col: 'col-12',
      display: {
        edit: true,
      },
      props: {
        table: true,
        tableProps: {
          rankDrag: true,
          hideDefaultFooter: true,
          disablePagination: true,
          disableSort: true,
        },
        relatedFields: [
          {
            key: 'status',
            label: 'generic.state',
            type: 'status',
            display: {
              table: true,
            },
          },
          {
            key: 'title',
            label: 'generic.title',
            type: 'text',
            display: {
              table: true,
            },
          },
          {
            key: 'image',
            label: 'generic.image',
            type: 'media',
            related: 'image { id, filename, file, type, dimensions, weight }',
            listing_attribute: 'file',
            listing_attributes: 'id,file',
            display: {
              table: true,
            },
            props: {
              typeFilter: ['image/%']
            },
          },
        ],
        actions: {
          toEditRows: true,
          deleteRows: true,
          addRows: true,
          selectRows: true,
        },
      },
    },
    { ...blocks, parent_module: 'award' },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
