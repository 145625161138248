import jwt from 'jsonwebtoken';

export default {
  authLoginSuccess (state, { token }) {
    const payload = jwt.decode(token);
    const { id, email, role, firstname, lastname } = payload;
    const user = { id, email, firstname, lastname };
    const permissionsArray = role && role.userrightsmodule ? role.userrightsmodule : [];
    state.user = user;
    state.token = token;
    state.roles = [role];
    state.userPermissions = permissionsArray;
  },
  authLoginError (state) {
    state.user = {};
    state.token = null;
    state.roles = [];
    state.userPermissions = [];
  },
  authLogout (state) {
    state.user = {};
    state.token = null;
    state.roles = [];
    state.userPermissions = [];
  },
  authLogoutError  (state) {
    state.user = {};
    state.token = null;
    state.roles = [];
    state.userPermissions = [];
  },
  setRequestedPath (state, requestedPath) {
    state.requestedPath = requestedPath;
  },
};
