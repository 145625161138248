export default {
  // Web site global label
  global: {
    site: 'AX2 Boilerplate',
    developed_by: 'Développé par',
    switchlang: 'English',
    acronymLang: 'EN',
  },
  // Language label
  contentLang: {
    fr: 'Français',
    en: 'Anglais',
  },
  // User interface content
  ui: {
    welcome: 'Bienvenue',
    close: 'Fermer',
    login: 'Se connecter',
    logout: 'Déconnexion',
    read: 'Lire',
    edit: 'Modifier',
    add: 'Ajouter',
    export: 'Exporter',
    duplicate: 'Dupliquer',
    copy_to: 'Copier vers',
    cancel: 'Annuler',
    confirm: 'Confirmer',
    save: 'Sauvegarder',
    reset: 'Réinitialiser',
    search: 'Recherche',
    select_image: 'Sélectionner une image',
    with_image: 'Avec image',
    without_image: 'Sans image',
    delete: 'Supprimer',
    publish: 'Publier',
    unpublish: 'Dépublier',
    preview: 'Aperçu',
    new_draft: 'Nouveau Brouillon',
    show_filters: 'Montrer les filtres',
    hide_filters: 'Cacher les filtres',
    select_status: 'Sélectionner un état',
    select_items: 'Sélectionner des',
    select_hour: 'Sélectionner heure',
    select_day: 'Sélectionner jour',
    select_date_start: 'Date de début',
    select_date_end: 'Date de fin',
    show_treeview: 'Montrer en arborescence',
    show_images: 'Montrer les images',
    hide_images: 'Cacher les images',
    results: 'Résultat(s)',
    no_data: 'Aucune donnée disponible',
    menu_expanded: 'Garder le menu élargi',
    menu_minified: 'Minifier le menu',
  },
  // Dialogs : every alert
  dialogs: {
    login_success: 'Connexion réussie !',
    logout_success: 'Déconnexion réussie !',
    token_expired: 'Votre session a expirée.',
    add_success: 'Ajout réussi !',
    update_success: 'Modification réussie !',
    duplicate_success: 'Duplication réussie !',
    remove_success: 'Suppression réussie !',
    loading: 'Chargement...',
    remove_title: 'Suppression',
    remove_subtitle: 'Voulez-vous vraiment supprimer cet élément ?',
    remove_subtitle_dynamic: 'Voulez-vous vraiment supprimer l\'élément "{title}" ?',
    exit_without_saving_title: 'Quitter sans sauvegarder',
    exit_without_saving_subtitle: 'Vous avez des changements en cours. Voulez-vous vraiment quitter ce module sans sauvegarder ?',
    reset_password_success: 'Changement de mot de passe réussi ! Vous pouvez maintenant vous connecter avec vos nouveaux accès.',
  },
  // Warnings : every warnings alert
  warnings: {
    content_lang: 'L\'élément n\'a pas été sauvegardé dans cette langue.',
  },
  // Errors : every errors alert
  errors: {
    no_modules: 'Vous avez accès à aucun modules. Veuillez contacter votre administrateur.',
    no_results: 'Aucun résultat trouvé',
    field_error_title: 'Sauvegarde échouée',
    field_error_subtitle: 'Veuillez réviser les {element}',
    required: 'Ce champs est requis',
    link_wrong_format_abs: 'Format de lien invalide: Le lien doit être absolu (http[s]://exemple.com)',
    link_wrong_format_abs_rel_email: 'Format de lien invalide: Le lien doit être absolu (http[s]://exemple.com), relatif (/exemple) ou de type courriel (mailto:john@exemple.com)',
    color_wrong_format: 'Couleur HEXA invalide (transparence requise: #xxxxxxFF)',
    max_300: '300 caractères max.',
    max: '{element} caractères max.',
    error_occured: 'Une error s\'est produite',
    login_error: 'Courriel ou mot de passe erroné',
    forgot_password_error: 'Nous n\'avons pas pu trouver un utilisateur actif existant avec l\'adresse courriel fournie.',
    translation_error: 'Aucune traduction pour l\'élement {id}',
    date_invalid: 'Date non valide',
    phone_invalid: 'Numéro de téléphone invalide',
    email_invalid: 'Courriel invalide',
    price_invalid: 'Prix invalide',
    multiple_errors: 'Il semble que quelque chose ne s\’est pas bien passé, il y a plusieurs erreurs dans le formulaire.',
    form_contains: 'Le formulaire contient',
    error: 'erreur',
    errors: 'erreurs',
    password_format: 'Le mot de passe doit contenir au moins une lettre majuscule et au moins un chiffre (sans espace, caractère accentué ou caractère spécial) et entre 8 et 20 caractères',
    password_match: 'Les mots de passe doivent correspondre',
    wrong_format: 'Le fichier "{fileName}" n\'est pas supporté. Les fichiers supportés sont: {acceptedFormats}.',
    file_too_large: 'Le fichier "{fileName}" dépasse la taille maximale permise ({fileSize}/{sizeLimit}).',
  },
  // Login page
  login: {
    hi: 'Bonjour',
    title: 'Connexion',
    email: 'Courriel',
    password: 'Mot de passe',
    stay_logged_in: 'Se souvenir de moi',
    subtitle: 'Système de gestion d\'entreprise',
    forgot_password: 'Mot de passe oublié?',
    forgot_password_title1: 'Vous avez besoin de réinitialiser votre mot de passe?',
    forgot_password_desc1: 'Veuillez nous fournir votre adresse courriel et nous vous enverrons un lien de réinitialisation de votre mot de passe.',
    forgot_password_title2: 'Un courriel a été envoyé!',
    forgot_password_desc2: 'Vous devriez recevoir le courriel de réinitialisation du mot de passe dans quelques instants. Une fois que vous avez réinitialisé votre mot de passe, connectez-vous à votre compte pour procéder.',
    email_forgot_password_title: 'Notification de réinitialisation du mot de passe',
    email_forgot_password_desc1: 'Vous avez récemment demandé la réinitialisation de votre mot de passe pour votre compte {site}. Cliquez sur le bouton ci-dessous pour le réinitialiser.',
    email_forgot_password_admin_desc1: 'Si vous n\'avez pas demandé de réinitialisation de mot de passe, veuillez ignorer cet e-mail et notifier votre',
    email_forgot_password_admin_desc2: 'Administrateur système',
    email_forgot_password_trouble_desc: 'Si vous ne parvenez pas à cliquer sur le bouton de réinitialisation du mot de passe, copiez et collez l\'URL ci-dessous dans votre navigateur Web:',
    reset_password: 'Réinitialiser votre mot de passe',
    new_password: 'Nouveau mot de passe',
    confirm_password: 'Confirmer le mot de passe',
    back_to_login: 'Retour à la connexion',
    back: 'Retour',
    submit: 'Soumettre',
    emails: {
      title: 'Vous nous avez notifié que vous avez oublié votre mot de passe.',
      desc: 'Pour réinitialiser votre mot de passe de façon sécuritaire, veuillez cliquer sur le bouton ci-dessous:',
      action: 'Réinitialiser mon mot de passe',
    },
  },
  // Dashboard main menu
  dashboard: {
    title: 'Tableau de bord',
    your_profile: 'Votre profil',
    recent_items: 'Inscriptions récentes',
    welcome: 'Bienvenue',
  },
  // Generic label
  generic: {
    general_info: 'Informations générales',
    online: 'En ligne',
    offline: 'Hors Ligne',
    inprogress: 'Édition en cours',
    email: 'Courriel',
    first_name: 'Prénom',
    last_name: 'Nom',
    family_name: 'Nom de famille',
    fullname: 'Nom',
    date: 'Date',
    list: 'Liste',
    state: 'État',
    link: 'Lien',
    rows_per_page: 'Rangées par page',
    main_group: 'Informations générales',
    title: 'Titre',
    subtitle: 'Sous-titre',
    description: 'Description',
    tags: 'Tags',
    edit_tag: 'Editer les tags',
    image: 'Image',
    video: 'Vidéo',
    pdf: 'PDF',
    zip: 'Zip',
    ppt: 'Powerpoint',
    filename: 'Nom de fichier',
    color: 'Couleur',
    created_since: 'Créé',
    not_applicable: 'Je préfère ne pas répondre',
    url: 'URL',
    contact_us: 'Nous contacter',
    select_value: 'Sélectionner une valeur',
    select_date: 'Sélectionner une date',
    top: 'Haut',
    right: 'Droite',
    bottom: 'Bas',
    left: 'Gauche',
    padding: 'Remplissage',
    margin: 'Marges',
    name: 'Nom',
    dimensions: 'Dimensions',
    weight: 'Poids',
    infos: 'Infos',
    or: 'ou',
    drop_file_here: 'Glisser-déposer un fichier ici',
    or_browse: 'Ou parcourir:',
    browse_computer: 'Votre ordinateur',
    browse_library: 'La librairie de médias',
    live: 'En ligne',
    draft: 'Brouillon',
    archived: 'Archivé',
    code: 'Code',
    internal_notes: 'Notes internes',
    phone: 'Téléphone',
    birthdate: 'Date d\'anniversaire',
    address: 'Adresse',
    city: 'Ville',
    postal_code: 'Code postal',
    lang: 'Langue',
    original_file: 'Voir le fichier original',
  },
  units: {
    kb: 'Ko',
    mb: 'Mo',
    gb: 'Go',
  },
  report: {
    bug_report: 'Rapporter un bug',
    bug_report_description: 'Si vous avez rencontré une erreur ou vous avez remarqué un comportement qui n\'était pas prévu, veuillez s\'il vous plaît nous le signaler. Vous pouvez utiliser les champs ci-dessous pour donner des détails sur le bug qui s\'est produit. N\'hésitez pas à ajouter des captures d\'écran pour nous aider à localiser le problème. Les champs identifiés par un astérisque (*) sont obligatoires.',
    feedback: 'Commentaires / Suggestions',
    feedback_description: 'Vous souhaitez partager votre avis sur le produit ? Vous avez des idée sur une fonctionnalité qui vous serait utile ? Nous sommes toujours heureux de recevoir les commentaires et suggestions de nos utilisateurs.',
    subject: 'Sujet',
    files: 'Attacher des fichiers (optionnel)',
    send: 'Envoyer le rapport',
    send_success: 'Le rapport a été envoyé avec succès. Merci !',
    send_failed: 'Une erreur s\'est produite lors de l\'envoie du rapport. Veuillez réessayer à nouveau.',
  }
};
