export default {
  specstype: 'Type',
  specstypes: 'Types',
  verbose_specstype: 'un type',
  options: 'Options',
  is_multiple: 'Multiple',
  is_optional: 'Optionnel',
  display_options: 'Options d\'affichage',
  display_configurator: 'Configurateur',
  use_exceptions: 'Utiliser les exceptions',
  select_specstypes: 'Sélectionner un type',
};
