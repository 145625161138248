<template>
  <v-app>
    <!-- App bar -->
    <app-bar />
    <!-- Navigation drawer -->
    <navigation-drawer />
    <!-- Contextual drawer -->
    <contextual-drawer v-if="contextualDrawerIsVisible === true">
      <portal-target name="contextual-drawer" />
    </contextual-drawer>
    <!-- Content -->
    <v-content>
      <content-bar :style="{ visibility: $route.meta.contentBar ? 'visible' : 'hidden' }" />
      <v-container
        :style="containerStyle"
        fluid
      >
        <router-view />
      </v-container>
    </v-content>
    <!-- Snackbar -->
    <snackbar />
  </v-app>
</template>

<script>
import { mapState } from 'vuex';
import { Wormhole } from 'portal-vue';

import AppBar from '@/components/AppBar/AppBar';
import NavigationDrawer from '@/components/NavigationDrawer/NavigationDrawer';
import ContextualDrawer from '@/components/ContextualDrawer/ContextualDrawer';
import Snackbar from '@/components/Snackbar/Snackbar';
import ContentBar from '@/components/ContentBar/ContentBar';

export default {
  name: 'MainLayout',
  components: {
    AppBar,
    NavigationDrawer,
    ContextualDrawer,
    ContentBar,
    Snackbar,
  },
  computed: {
    ...mapState('ui', ['contextualDrawerIsMini']),
    contextualDrawerIsVisible () {
      return Wormhole.hasContentFor('contextual-drawer');
    },
    containerStyle () {
      let style = null;
      if (this.contextualDrawerIsVisible === true && this.$vuetify.breakpoint.mdAndUp) {
        style = this.contextualDrawerIsMini === true ? 'padding-right: 72px;' : 'padding-right: 272px;';
      }
      return style;
    },
  },
};
</script>
