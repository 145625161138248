export default {
  id: 'events',
  idSingular: 'event',
  saveable: true,
  typeName: 'event',
  label: 'event.events',
  label_details: 'event.event',
  verbose_label_singular: 'event.verbose_event',
  itemBreadcrumb: 'title',
  i18n: true,
  filters: [
    {
      id: 'articletype',
      type: 'select',
      whereClause: 'articletype_id',
      label: 'articletype.select_articletype',
    },
    {
      id: 'dates',
      type: 'daterange',
      label: 'event.published_at',
      whereClause: [
        'published_at[>=]',
        'published_at[<=]',
      ],
    },
  ],
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  fields: [
    {
      key: 'id',
      label: 'ID',
      type: 'text',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
    },
    {
      key: 'status',
      type: 'status',
      label: 'generic.state',
      display: {
        edit: true,
        add: true,
        listing: true,
      },
      listingStyle: {
        columnWidth: 80,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'articletype',
      groupkey: 'main',
      label: 'articletype.articletype',
      type: 'select',
      related_key: 'articletypes',
      related: 'articletype { id, title }',
      related_table: 'articletypes',
      related_id: 'articletype_id',
      listing_attribute: 'title',
      listing_attributes: 'id,title',
      related_fields: [
        'title',
        'id'
      ],
      notsortable: true,
      display: {
        listing: true,
        add: true,
        edit: true,
      },
      required: true,
      props: {
        clearable: true,
      }
    },
    {
      key: 'description',
      groupkey: 'main',
      label: 'generic.description',
      type: 'textarea',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
      props: {
        counter: 140,
      },
    },
    {
      key: 'link',
      groupkey: 'main',
      label: 'generic.link',
      type: 'text',
      display: {
        edit: true,
        add: true,
      },
      required: true,
      props: {
        counter: 255,
        onFocusDefaultValue: 'http://',
        rules: [
          value => {
            const pattern = /^https?:\/\/[a-z0-9\.\-]*\.[a-z]{1,}([\/a-z\.\?=0-9&\-\_]*)?$/i;
            return value === null || value === '' || (value && pattern.test(value)) || 'errors.link_wrong_format';
          },
        ]
      },
    },
    {
      key: 'event_date',
      groupkey: 'main',
      label: 'event.event_date',
      type: 'date',
      display: {
        edit: true,
        add: true,
      },
      required: true,
    },
    {
      key: 'published_at',
      groupkey: 'main',
      label: 'article.published_at',
      type: 'datetime',
      display: {
        add: true,
        edit: true,
        listing: true,
      },
    },
    {
      key: 'image',
      groupkey: 'main',
      label: 'generic.image',
      type: 'media',
      related: 'image { id, filename, file, type, dimensions, weight }',
      listing_attribute: 'file',
      listing_attributes: 'id,file',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      international: true,
      props: {
        typeFilter: ['image/%']
      },
    },
    {
      key: 'created_since',
      groupkey: 'main',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
