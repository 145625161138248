export default {
  id: 'productsections',
  idSingular: 'productsection',
  saveable: true,
  typeName: 'productsection',
  label: 'productsection.productsections',
  label_details: 'productsection.productsection',
  verbose_label_singular: 'productsection.verbose_productsection',
  itemBreadcrumb: 'title',
  i18n: true,
  groupFields: [
    {
      key: 'main',
      label: 'generic.general_info',
      hashLink: 'general-info',
      panel: true,
    },
  ],
  filters: [],
  fields: [
    {
      key: 'id',
      label: 'ID',
      groupkey: 'main',
      fetch: {
        listing: true,
      },
      search: true,
    },
    {
      key: 'i18n',
      fetch: {
        edit: true,
      },
    },
    {
      key: 'title',
      groupkey: 'main',
      label: 'generic.title',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
      international: true,
    },
    {
      key: 'code',
      groupkey: 'main',
      label: 'generic.code',
      type: 'text',
      display: {
        edit: true,
        listing: true,
        add: true,
      },
      search: true,
      required: true,
    },
    {
      key: 'internal_notes',
      groupkey: 'main',
      label: 'generic.internal_notes',
      type: 'textarea',
      display: {
        edit: true,
        add: true,
      },
      search: true,
    },
    {
      key: 'created_since',
      groupkey: 'info',
      label: 'generic.created_since',
      type: 'text',
      sortClause: 'created_at',
      display: {
        listing: true,
      },
      listingStyle: {
        columnWidth: 95,
      },
    },
  ]
};
