import {
  setAuthorizationHeader,
  setCookie,
  getCookie,
  removeCookie,
} from '@/helpers/utils';
import { AUTH_TOKEN_LOCAL_STORAGE_KEY } from '@/helpers/constants';
import { onLogin, onLogout } from '../../vue-apollo.js';

export default {
  /**
   * Basic login with email & password
   *
   * @param      {Object}    arg1           Vuex parameters
   * @param      {Function}  arg1.commit    Commit method
   * @param      {Object}    arg2           Action options
   * @param      {string}    arg2.email     The email
   * @param      {string}    arg2.password  The password
   * @return     {Promise}
   */
  login ({ commit, state, getters }, { email, password, rememberMe = false }) {
    const postData = { email, password };
    const { apiRoot, endpoints } = this.$xms.config;

    return new Promise(async (resolve, reject) => {
      try {
        const endpoint = endpoints.signup ? apiRoot + endpoints.signup : false;
        const response = await this.$http.post(endpoint, postData);
        const { token } = response.data;
        const apolloClient = this.$apollo.defaultClient;
        await onLogin(apolloClient, token);
        const cookieOptions = {
          expires: rememberMe ? 7 : null,
        };

        // Must commit login success first, before finding where to redirect
        commit('authLoginSuccess', { token });
        setCookie(AUTH_TOKEN_LOCAL_STORAGE_KEY, token, cookieOptions);
        setAuthorizationHeader(this.$http, token);

        // Find where to redirect user after login
        const moduleCanAccess = getters.firstModulePathAccessibleToUser;
        let redirectPath = moduleCanAccess !== undefined ? moduleCanAccess : '/unauthorized';
        // Overwrite with requested path if it is available
        if (state.requestedPath !== null) {
          const requestedRoute = this.$router.matcher.match(state.requestedPath);
          if (getters.canAccess(requestedRoute) === true) {
            redirectPath = state.requestedPath;
          }
        }

        this.$router.push(redirectPath);
        resolve();
      } catch (error) {
        commit('authLoginError');
        reject(error);
      }
    });
  },
  /**
   * Attempt to automatically login using locally stored token
   *
   * @param      {Object}    arg1           Vuex parameters
   * @param      {Function}  arg1.commit    Commit method
   * @param      {Function}  arg1.dispatch  Dispatch method
   * @param      {Object}    arg1.getters   Getters object from store
   * @return     {Promise}
   */
  autoLogin ({ commit, dispatch, getters }) {
    const { apiRoot, endpoints } = this.$xms.config;

    return new Promise(async (resolve, reject) => {
      const onLoginSuccess = token => {
        setAuthorizationHeader(this.$http, token);
        commit('authLoginSuccess', { token });
        resolve();
      };
      const onLoginFailure = (error = null) => {
        commit('authLoginError');
        dispatch('logout', { forceBackToLogin: false });
        if (error !== null) {
          reject(error);
        }
      };

      // Get token from cookies or local storage
      const { hasAuthToken } = getters;
      if (hasAuthToken === true) {
        const token = getters.getAuthToken;
        // We'll need an endpoint dedicated to verifying/refreshing access tokens
        try {
          const endpoint = endpoints.verify ? apiRoot + endpoints.verify : false;
          const response = await this.$http.post(endpoint, { token });
          if (response.status === 200) {
            const newToken = response.data.token;
            onLoginSuccess(newToken);
          }
        } catch (error) {
          if (error.response !== undefined && error.response.status === 400) {
            onLoginFailure(error);
          }
        }
      } else {
        onLoginFailure();
      }
    });
  },
  /**
   * Logout current user
   *
   * @param      {Object}    arg1           Vuex parameters
   * @param      {Function}  arg1.commit    Commit method
   * @param      {Function}  arg1.dispatch  Dispatch method
   * @param      {Object}    arg1.getters   Getters object from auth store
   */
  logout ({ commit, dispatch, getters, state }, { forceBackToLogin, expiredToken }) {
    return new Promise(async (resolve, reject) => {
      try {
        // Check if the user was logged in before action begins.
        const shouldShowSnackbar = getters.isLoggedIn === true;
        removeCookie(AUTH_TOKEN_LOCAL_STORAGE_KEY);
        commit('authLogout');
        const apolloClient = this.$apollo.defaultClient;
        onLogout(apolloClient);
        if (expiredToken === true) {
          dispatch('ui/showSnackbar', { message: 'dialogs.token_expired', type: 'warning' }, { root: true });
        } else if (shouldShowSnackbar === true) {
          dispatch('ui/showSnackbar', { message: 'dialogs.logout_success', type: 'success' }, { root: true });
        }
        // Redirect to login or desired path if available
        if (forceBackToLogin === false
          && state.requestedPath !== null
          && state.requestedPath !== '/') {
          const requestedRoute = this.$router.matcher.match(state.requestedPath);
          const redirectToRequestedRoute = getters.canAccess(requestedRoute) === true;
          this.$router.push(redirectToRequestedRoute ? requestedRoute.fullPath : '/login');
        } else {
          this.$router.push('/login');
        }
        resolve();
      } catch (error) {
        commit('authLogoutError');
        reject(error);
      }
    });
  },
  /**
   * Set Requested Path before login
   *
   * @param      {Object}    arg1           Vuex parameters
   * @param      {Function}  arg1.commit    Commit method
   * @param      {String}    requestedPath  The path user is initially accessing
   */
  setRequestedPath ({ commit }, requestedPath) {
    commit('setRequestedPath', requestedPath);
  },
};
